@font-face {
  font-family: 'Press Start 2P';
  src: url('./PressStart2P-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  background: url('./borgbackground3.png') no-repeat center center fixed;
  background-size: cover;
}

#borg-title {
  text-align: center;
  font-family: 'Press Start 2P', cursive;
  font-size: 55px;
  color: #FF00FF; /* Bright purple */
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 5px #FF00FF, 0 0 10px #FF00FF, 0 0 20px #FF00FF;
  margin-bottom: 20px;
}

#drink-selector-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

#drink-selector-container select,
#fraction-selector,
#add-button {
  font-family: 'Press Start 2P', cursive;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  border: 2px solid #00ff00;
  padding: 8px 16px;
  cursor: pointer;
  height: 40px; /* Adjust the height as needed */
  margin-left: 10px; /* Add some spacing between the selector and the button */
}

.button {
  font-family: 'Press Start 2P', cursive;
  font-size: 16px;
  color: #fff;
  border: 2px solid #00ff00;
  background-color: #000;
  padding: 8px 16px;
  cursor: pointer;
}

#drink-selector-container select,
#fraction-selector {
  margin-right: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

#fraction-selector::after {
  content: "\25BC";
  font-family: 'Press Start 2P', cursive;
  font-size: 16px;
  color: #00ff00;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
}

#add-button,
.button {
  background-color: #000;
  border: 2px solid #00ff00; /* Add green border */
  padding: 8px 16px;
  font-size: 16px; /* Set font size */
  margin: 0; /* Remove default margins */
}

#add-button:hover,
.button:hover {
  background-color: #333;
}

#tally-container {
  text-align: center;
  margin-top: 50px;
}

#tally-title,
.tally-item {
  font-family: 'Press Start 2P', cursive;
  font-size: 16px;
  color: #fff;
  
}

#tally-title {
  color: rgb(255, 255, 255);
  font-size: 30px;
}

.tally-list {
  list-style-type: none;
  padding: 0;
}

.tally-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: black; /* Set black background */
  padding: 10px; /* Add padding for spacing */
  border-radius: 5px; /* Add rounded corners */
  border: 2px solid #00ff00; /* Add green border */
}

.tally-item-name {
  color: #f0f;
}

#total-shots {
  font-family: 'Press Start 2P', cursive;
  font-size: 20px;
  color: #f0f;
  background-color: black; /* Set black background */
  border: 2px solid #00ff00; /* Add green border */
  padding: 8px 16px;
  margin-top: 20px;
}

#show-total-button {
  font-family: 'Press Start 2P', cursive;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 20px;
}

#footer {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 5px;
}

#author {
  font-family: 'Press Start 2P', cursive;
  font-size: 1.4vw;
  color: rgb(255, 255, 255, 0.8);
}

.opening-description {
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  padding: 20px;
  background-color: #000000;
  opacity: 0.9;
  border: 2px solid #00ff00;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  z-index: 9999;
}

.opening-description h2 {
  margin-bottom: 10px;
  font-family: 'Press Start 2P', cursive;
  font-size: 20px;
}

.opening-description p {
  margin-bottom: 45px;
  font-family: 'Press Start 2P', cursive;
  font-size: 16px;
}

.close-button {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Press Start 2P', cursive;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  border: 2px solid #00ff00;
  padding: 5px 10px 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button:hover {
  color: #00cc00;
}

s {
  text-decoration: line-through;
  text-decoration-thickness: 3px; 
  
}

#shareButton {
  display: none;
}
.qr-code-button,
.qr-code-container {
  display: none;
}

.confetti-container {
  position: relative;
  left:50%;
}

.reset-button {
  font-family: 'Press Start 2P', cursive;
  font-size: 16px;
  color: #ffffff;
  border: 2px solid #00ff00;
  background-color: #000;
  padding: 8px 16px;
  cursor: pointer;
  margin-left: 2%;
}

@media only screen and (max-device-width: 428px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 375px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 414px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 360px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 390px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 768px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 392px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 320px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 412px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 424px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 480px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 600px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 620px) and (orientation: portrait) and (pointer: coarse) and (hover: none),
       only screen and (max-device-width: 667px) and (orientation: portrait) and (pointer: coarse) and (hover: none) {
  body {
    background: url('./borgbackgroundmobile.png') no-repeat center center fixed;
    background-size: cover;
  }
  
  #borg-title {
    font-size: 11vw;
    text-shadow: 0 0 3px #FF00FF, 0 0 4px #FF00FF, 0 0 8px #FF00FF;
    margin-bottom: 3vw;
  }

  #drink-selector-container select,
  #fraction-selector,
  #add-button {
    font-size: 2.4vw;
    height: 7vw;
    padding: 2vw 1vw;
    margin-left: 1vw;
  }

  .button {
    font-size: 3vw;
    padding: 1.4vw 2.8vw;
  }

  #fraction-selector::after {
    font-size: 3vw;
  }

  #add-button,
  .button {
    padding: 1.4vw 2.8vw;
  }

  #tally-container {
    margin-top: 7vw;
  }

  #tally-title,
  .tally-item {
    font-size: 3vw;
  }

  #tally-title {
    font-size: 4.5vw;
  }

  .tally-item {
    margin-bottom: 1.4vw;
    padding: 1.4vw;
    border-radius: 0.7vw;
  }

  #total-shots {
    font-size: 3.5vw;
    padding: 1.4vw 2.8vw;
    margin-top: 3vw;
  }

  #show-total-button {
    font-size: 3vw;
    padding: 1.4vw 2.8vw;
    margin-top: 3vw;
  }

  #footer {
    padding: 0.8vw;
  }

  #author {
    font-size: 1.4vw;
    color: rgb(255, 255, 255, 0.8);
  }

  .opening-description {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    padding: 2%;
    background-color: #000000;
    opacity: 0.9;
    border: 0.3em solid #00ff00;
    color: #ffffff;
    font-size: 1.5vw;
    text-align: center;
    z-index: 9999;
  }
  
  .opening-description h2 {
    margin-bottom: 1vw;
    font-family: 'Press Start 2P', cursive;
    font-size: 7vw;
  }
  
  .opening-description p {
    margin-bottom: 11vw;
    font-family: 'Press Start 2P', cursive;
    font-size: 4.5vw;
  }
  
  .close-button {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Press Start 2P', cursive;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
    border: 2px solid #00ff00;
    padding: 5px 10px 5px 15px; /* Added more padding on the left side */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .close-button:hover {
    color: #00cc00;
  }
  
  s {
    text-decoration: line-through;
    text-decoration-thickness: 0.2em;
  }

  #shareButton {
    display:block;
    position: fixed;
    bottom: 2%;
    left: 2%;
    padding: 2% 4%;
    background-color: #000;
    color: #fff;
    border: 0.3vw solid #00ff00;
    font-size: 2.5vw;
    font-family: "Press Start 2P", cursive;
    cursor: pointer;
  }

  .qr-code-button {
    display:block;
    position: fixed;
    bottom: 2%;
    right: 2%;
    padding: 2% 4%;
    background-color: #000;
    color: #fff;
    border: 0.3vw solid #00ff00;
    font-size: 2.5vw;
    font-family: "Press Start 2P", cursive;
    cursor: pointer;
  }
  .qr-code-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    height: 90vw;
    max-width: 350px;
    max-height: 350px;
    background-color: #000;
    border: 3px solid #00ff00;
  }
  .reset-button {
    font-size: 3vw;
    padding: 1.4vw 2.8vw;
    margin-top: 3vw;
  }
}
